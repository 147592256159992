import http from './http'

export function branches (params) {
  return http.get(`/branches`, { params })
}

export function search (params) {
  return http.get(`/search/rental`, { params })
}

export function summary (token) {
  return http.get(`/search/rental/${token}`)
}

export function locationLookup (query) {
  let input
  // Used because most api calls use "query" for search term rather than "input"
  if (query.query) {
    input = query.query
  } else {
    input = query
  }
  const result = []
  return http.get(`/search/rental/locationlookup`, {
    params: { input }
  })
    .then(response => {
      response.data.business_addresses.forEach(address => {
        result.push({
          label: address.text,
          value: address.text,
          code: address.text,
          icon: 'domain',
          type: 'business'
        })
      })
      response.data.airport.forEach(airport => {
        result.push({
          label: airport.name,
          value: airport.code,
          icon: 'flight',
          append: airport.code,
          code: airport.code,
          type: 'branch',
          color: 'red-5'
        })
      })
      response.data.branch.forEach(branch => {
        result.push({
          label: branch.name,
          value: branch.code,
          icon: 'directions_walk',
          append: branch.code,
          code: branch.code,
          type: 'branch',
          color: 'lime-5'
        })
      })
      response.data.addresses.forEach(a => {
        result.push({
          label: a.text,
          value: a.text,
          code: a.text,
          icon: 'home',
          type: 'home'
        })
      })
      return result
    })
}
