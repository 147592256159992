<template>
  <q-drawer
    v-model="show"
    side="right"
    elevated
  >
    <div class="column items-stretch">
      <q-stepper
        ref="stepper"
        v-model="currentStep"
        vertical
        class="row full-width"
        header-nav
      >
        <q-step
          name="ondemand-car-hire-results"
          default
          :title="$t('availability')"
          :caption="$t('rental.choose_vehicle')"
          :done="navigatable.includes('ondemand-car-hire-results')"
          :header-nav="navigatable.includes('ondemand-car-hire-results')"
        >
          <q-btn
            v-if="stash.selected"
            @click="$router.push({name: 'ondemand-car-hire-summary', params: {token: stash.selected.value.token}})"
          >
            {{ $t('continue') }}
          </q-btn>
        </q-step>
        <q-step
          name="ondemand-car-hire-summary"
          :title="$t('summary')"
          :caption="$t('review_and_submit')"
          :done="navigatable.includes('ondemand-car-hire-summary')"
          :header-nav="navigatable.includes('ondemand-car-hire-summary')"
        />
        <q-step
          name="ondemand-car-hire-payment-card"
          :title="$t('payment')"
          :done="navigatable.includes('ondemand-car-hire-payment-card')"
          :header-nav="navigatable.includes('ondemand-car-hire-payment-card')"
        />
        <q-step
          name="ondemand-car-hire-complete"
          :title="$t('confirmation')"
          :done="navigatable.includes('ondemand-car-hire-complete')"
          :header-nav="navigatable.includes('ondemand-car-hire-complete')"
        />
      </q-stepper>
      <div class="overview-tickets row column group generic-margin" />
    </div>
  </q-drawer>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex'
export default {
  name: 'OndemandCarHireSidebar',
  data () {
    return {
      show: !this.$q.platform.is.mobile
    }
  },
  computed: {
    ...mapGetters({
      stash: 'ondemand/stash'
    }),
    currentStep: {
      get () {
        return this.$route.name
      },
      set (step) {
        this.$router.push({ name: step })
      }
    },
    navigatable () {
      let availablePaths = []
      switch (this.$route.name) {
      case 'ondemand-car-hire-complete':
        availablePaths.push('ondemand-car-hire-payment-card')
      case 'ondemand-car-hire-payment-card':
        availablePaths.push('ondemand-car-hire-summary')
      case 'ondemand-car-hire-summary':
        availablePaths.push('ondemand-car-hire-results')
        break
      }
      return availablePaths
    }
  }
}
</script>

<style lang="stylus" scoped>
.overview-price
  padding 8px
  .total
    font-size 1em
  .price
    margin 4px 0
    font-size 1.6em
  .people
    font-size 0.8em
</style>
