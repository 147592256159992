<template>
  <q-page v-if="summary" padding>
    <div class="row q-col-gutter-sm">
      <div class="col-sm-12">
        <q-card class="bg-white">
          <q-card-section :class="`bg-primary`">
            {{ $t('booking.summary') }}
          </q-card-section>
          <q-card-section>
            <vehicle-card :item="summary" :vendor="vendor" />
            <q-separator class="hr" />
            <div v-for="item in summary.payment_breakdown.hire_cost_breakdown" :key="item.description" class="row">
              <span class="offset-6 col-4 text-grey-9 text-right">{{ item.description }}</span>
              <span class="col-2 text-grey-9 text-right">{{ item.amount.text }}</span>
            </div>
            <div v-if="summary.payment_breakdown.vat" class="row">
              <span class="offset-6 col-4 text-grey-9 text-right">{{ $t('tax.vat') }}</span>
              <span class="col-2 text-grey-9 text-right">{{ summary.payment_breakdown.vat.text }}</span>
            </div>
            <div class="row">
              <span class="offset-6 col-4 text-grey-9 text-right">{{ $t('booking.fee') }}</span>
              <span class="col-2 text-grey-9 text-right">{{ summary.payment_breakdown.mobilleo_booking_fee.text }}</span>
            </div>

            <q-separator class="hr" />

            <h6 class="row text-bold">
              <span class="offset-6 col-4 text-grey-9 text-right">{{ $t('total_cost') }}</span>
              <span
                class="col-2 text-grey-9 text-right"
              >{{ summary.payment_breakdown.total.text }}</span>
            </h6>
          </q-card-section>
        </q-card>
        <div class="col-sm-12">
          <m-banner
            v-if="summary.included_costs"
            :title="summary.included_costs.title"
            :message="summary.included_costs.text"
            color="primary"
          />
        </div>

        <div class="col-sm-12">
          <m-attach-to-booking-request
            :suggested="summary.suggested_journey_name"
            :username="stash.parameters.traveller.value"
            :journey="journey"
            :show-travel-type="travelPolicy && travelPolicy.rental.enabled === true"
            @change="(val) => $store.dispatch('ondemand/journey', val)"
            @reasonChange="(val) => reason_for = val"
            @labelChange="(val) => labels = val"
            @travelTypeChange="(val) => travelType = val"
          />
        </div>
        <div class="col-sm-12">
          <payment-buttons
            :total-amount="summary.payment_breakdown.total.amount"
            :requester="stash.parameters.requester"
            :traveller="stash.parameters.traveller || stash.parameters.requester"
            :journey="journey"
            :reason-for="reason_for"
            :labels="labels"
            :booking-token="booking_token"
            :travel-type="(travelPolicy && travelPolicy.rental.enabled === true) || !organisation ? travelType : 'Private'"
          />
          <m-terms />
        </div>
      </div>
    </div>
  </q-page>
</template>

<script>
import { mapGetters } from 'vuex'
import authentication from 'mixins/authentication'
import date from 'utils/date-time'
import loading from 'utils/loading'
import { summary } from 'api/rental'
import paymentButtons from 'pages/payments/payment-method-buttons'
import vehicleCard from './vehicle-card'
import store from 'store'
import i18n from 'i18n'
import { MAttachToBookingRequest, MBanner, MTerms } from 'components/'
import { handleErrors } from 'utils/utils'

export default {
  name: 'OndemandCarHireSummary',
  components: {
    vehicleCard,
    paymentButtons,
    MAttachToBookingRequest,
    MBanner,
    MTerms
  },
  filters: {
    date: value => date.toMediumDateTime(value),
    formatLocation (location) {
      return `
      ${location.address1 ? location.address1 + ',' : ''}
      ${location.address2 ? location.address2 + ',' : ''}
      ${location.address3 ? location.address3 + ',' : ''}
      ${location.city ? location.city + ',' : ''}
      ${location.county ? location.county + ',' : ''}
      ${location.postal_code ? location.postal_code + '' : ''}
      `
    }
  },
  mixins: [authentication],
  data () {
    return {
      summary: null,
      reason_for: null,
      vendor: null,
      labels: null,
      travelType: null,
      booking_token: null
    }
  },
  computed: {
    ...mapGetters({
      journey: 'ondemand/journey',
      stash: 'ondemand/stash',
      partner: 'partner',
      organisation: 'organisation'
    }),
    daysOnHire () {
      let diff = date.getDateDiff(this.summary.return.date, this.summary.pickup.date, 'days')
      // Hire will always be for at least 1 day
      return diff > 0 ? `${diff} days` : `1 day`
    },
    travelPolicy () {
      if (!this.organisation) return null
      if (this.organisation?.attributes?.travel_policy) {
        return this.organisation.attributes.travel_policy
      }
      return { rental: { enabled: true } }
    }
  },
  mounted () {
    this.vendor = process.env.VUE_APP_RENTAL_VENDOR
  },
  methods: {
    icon (addressType = 'deliveryCollection') {
      return {
        deliveryCollection: 'home',
        walkIn: 'directions_walk',
        airport: 'flight'
      }[addressType]
    }
  },
  async beforeRouteEnter (to, from, next) {
    const stash = store.getters['ondemand/stash']
    const partner = store.getters.partner
    loading.start({
      message: i18n.t('loading.searching.rental'),
      partnerSlug: partner.slug,
      spinnerColor: partner.hex
    })

    try {
      const { data } = await summary(stash.selected.value.token)

      next(vm => {
        vm.summary = data
        vm.booking_token = data.booking_token
        store.dispatch('ondemand/booking_token', data.booking_token)
        loading.stop()
      })
    } catch (err) {
      handleErrors(err)
      next(false)
      loading.stop()
    }
  }
}
</script>

<style lang="stylus" scoped>
button
  width 100%
span, .hr
  margin-top 16px
</style>
